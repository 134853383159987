export const menu = [
    {url : "/" , name: "Home"},
    {url : "/about" , name: "About"},
    {url : "/store" , name: "Store"}, 
]
export const catagoris_menu =[
    {url: "/men", name: "Men"},
    {url: "/women", name: "Women"},
    {url: "/kids", name: "Kids"}

]

export const menCollections = [ 
    {img:"/assets/men/items1.jpg", name: " Deep Slate Boxer Brief Pack of 2 ", collecion: "Morden Classic"},
    {img:"/assets/men/items2.jpg", name: " Deep Slate Boxer Brief Pack of 2 ", collecion: "Morden Classic"},
    {img:"/assets/men/items3.jpg", name: "  Black Olive Woven Bermuda  ", collecion: "USA Originals"},
    {img:"/assets/men/items4.jpg", name: " White Vest Pack of 3 ", collecion: "Every Basics"},
    {img:"/assets/men/items5.jpg", name: "  Deep Olive Polo T-Shirt  ", collecion: "International Collection"},
    {img:"/assets/men/items6.jpg", name: "  White Basic Under shirt Pack of 3  ", collecion: "Morden Classic"},
    {img:"/assets/men/items7.jpg", name: "  Graphite Sleep Shorts ", collecion: "Relax"},
    
]

export const womenCollections = [ 
    {img:"/assets/women/items1.jpg", name: "  White Plus Size Bra  ", collecion: "Essence "},
    {img:"/assets/women/items2.jpg", name: "  Black Plus Size Bra  ", collecion: "Soft Wonder"},
    {img:"/assets/women/items3.jpg", name: "  Dark Assorted Hipster Pack of 3  ", collecion: "Simple Comfort"},
    {img:"/assets/women/items4.jpg", name: "  Red Quartz Capri ", collecion: "Relax"},
    {img:"/assets/women/items5.jpg", name: "  Indigo Crush Polo Shirt ", collecion: "USA Originals"},
    {img:"/assets/women/items6.jpg", name: "  Red Quartz Capri  ", collecion: "Relex"},
    {img:"/assets/women/items7.jpg", name: "  Black Period Panty ", collecion: "Simple Comfort "},
    {img:"/assets/women/items8.jpg", name: "  Black Camisole  ", collecion: "Soft Wonder"},
    {img:"/assets/women/items9.jpg", name: "  Anemone T-Shirt Bra  ", collecion: "Soft Wonder"},
    
    
]
export const kidsCollections = [ 
    {img:"/assets/kids/items1.jpg", name: "  Dubarry Sweatshirt  ", collecion: "Athleisure"},
    {img:"/assets/kids/items2.jpg", name: "  Greenery Boys Sweatshirt  ", collecion: "Athleisure "},
    {img:"/assets/kids/items3.jpg", name: "  Pink Girls T-Shirt ", collecion: "Athleisure"},
    {img:"/assets/kids/items4.jpg", name: "  Print Assorted Girls Panty Pack   ", collecion: "Simple Comfort"},
    {img:"/assets/kids/items5.jpg", name: "  Green Boyes T-Shirt ", collecion: "Morden Classic"},
    {img:"/assets/kids/items6.jpg", name: "  Deep Slate Boxer  ", collecion: "Morden Classic"},
    {img:"/assets/kids/items7.jpg", name: "  Marina Girls Sleep Dress  ", collecion: "Relax"},
  
]


export const blogNews = [
    {img: "/assets/blog/blog1.webp", name: "Magic Undercup Bra: A Perfect Workmate who “KNOWS YOU.”" , date: "20 Aug 2023", info: "Over the years, the most significant piece of clothing in a woman’s wardrobe, her lingerie, has been significantly altered to support her lifestyle. Today, bras have transitioned into a highly functional and stylish product with comfort being the key element. Taking this idea ahead, Jockey has recently added Magic Undercup Bras to their wide & versatile collection."},
    {img: "/assets/blog/blog2.webp", name: "A brief history of men’s underwear" , date: "10 Jul 2023", info: "Men, you probably can’t imagine your life without your modern underwear today, right? But, did you know that this important piece of clothing in your wardrobe can be traced all the way back to 5400 BC? We are sure that the first man who decided to wear this kind of protection would have never imagined that someday this product would become a billion-dollar industry that would go on to focus exclusively on style, comfort, and gender appeal."},
    {img: "/assets/blog/blog3.webp", name: "Worried about your OOTD for the next job interview - Nail the look with Jockey" , date: "05 Jun 2023", info: "Are you preparing yourself for a brand-new job interview? Well, we suggest you start with your CV and then, move on to your outfit.Yes, your outfit! Like it or not, your first impression can be a dealbreaker. Plus, your attire should complement rather than distract from the real focus of your interview. So, while you prepare yourself and your resume, let us help you select pieces that will get you one step closer to your dream job. Shall we begin?"},
]