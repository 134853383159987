import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "../About/About.module.scss"
export default class About extends Component {
  static propTypes = {
    prop: PropTypes
  }

  render() {
    return (
      <div>
       <section className={styles.banner_about}>
        <div className={styles.banner_info}>
          <h1>About us</h1>
          <h3><a href='/'>Home </a>| About</h3>
        </div>
      </section>

      <section className={styles.info}>
        <div className='container'>
          <p>At HRS Jockey, we are proud to be a part of the globally renowned and trusted Jockey brand, known for its unparalleled comfort and style. With a legacy that spans decades, our dedication to delivering top-notch innerwear and apparel remains steadfast.</p>

          <p>Built on the foundations of innovation, comfort, and longevity, Jockey has become a symbol of exceptional craftsmanship and contemporary design. As an HRS Jockey Franchise, we uphold these principles by offering a diverse array of products tailored to fulfill our customers' everyday comfort and fashion desires.</p>

          <p>Our team is wholeheartedly committed to curating a collection that not only meets the highest standards but also resonates with the ever-evolving preferences of our esteemed customers. Each garment is thoughtfully crafted to ensure an impeccable fusion of style, fit, and practicality.</p>

          <p> Beyond just products, our hallmark lies in our customer-centric approach. Our highly trained staff is dedicated to assisting you in discovering the perfect items that align seamlessly with your personal preferences. We firmly believe that shopping is an immersive experience, and we are devoted to making it as delightful and smooth as possible.</p>

          <p>At HRS Jockey, we invite you to explore our range of products, experience our exceptional service, and become a part of the legacy that is synonymous with comfort, quality, and style.</p>
        </div>
      </section>
      </div>
    )
  }
}
