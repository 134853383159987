import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from "../Footer/Footer.module.scss"

export class Footer extends Component {
  static propTypes = {

  }

  render() {
    return (
      <div>
        <footer>
            <div className='container'>
                <div className={styles.wrap}>
                    <div className={styles.card}>
                        <a href='/'>
                            <img src='assets/logo.png' alt=''/>
                        </a>
                        <p>The customer is at the heart of our unique 
                        business model, which includes 
                        design.</p>
                    </div>
                    <div className={styles.card}>
                        <h4>Social Link </h4>
                        <ul>
                            <li><a href='https://www.facebook.com/profile.php?id=100095706243905'>Facebook</a></li>
                            <li><a href='https://www.instagram.com/jockeyhrs/'>Instagram</a></li>
                            
                        </ul>
                    </div>
                    <div className={styles.card}>
                        <h4>Quick Menu </h4>
                            <ul>
                                <li><a href='/'>Home</a></li>
                                <li><a href='/about'>About us</a></li>
                                <li><a href='/store'>Store</a></li>
                            </ul>
                    </div>
                    <div className={styles.card}>
                        <h4>Contact us </h4>
                        <ul>
                            <li><a href=''>+91 90656 29670 </a></li>
                            <li><a href=''>info@jockeyhrs.in</a></li>
                            <li><a href=''>White House compound,Opposite Circuit House ,Gaya-823001</a></li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footer_bottom}>
                    <p>@2023 copyright HRS Jockey | Designed By <a href='https://www.datavedam.com' target='_blank'>Datavedam</a></p>
                </div>
            </div>
        </footer>
      </div>
    )
  }
}

export default Footer
